export default {
  invoice_id: null,
  item_id: null,
  name: '',
  title: '',
  description: null,
  quantity: 1,
  cost: 0,
  nettotal:0,
  price: 0,
  discount_type: 'fixed',
  discount_val: 0,
  discount: 0,
  total: 0,
  totalTax: 0,
  totalSimpleTax: 0,
  totalCompoundTax: 0,
  invoice_type:null,
  tax: 0,
  taxes: [],
  cat_name: [],
  itemcategories:[]
}
