<template>
  <div class="main-content item-create">
    <div class="page-header">
      <h3 class="page-title">{{ isEdit ? $t('ItemCat.edit_item') : $t('ItemCat.new_item') }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/dashboard">{{ $t('general.home') }}</router-link></li>
        <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/itemsCategory">{{ $tc('ItemCat.title') }}</router-link></li>
        <li class="breadcrumb-item"><a href="#"> {{ isEdit ? $t('items.edit_item') : $t('items.new_item') }}</a></li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <form action="" @submit.prevent="submitItem">
            <div class="card-body">
              <div class="form-group">
                <label class="control-label">{{ $t('ItemCat.name') }}</label><span class="text-danger"> *</span>
                <base-input
                  v-model.trim="formData.name"
                  :invalid="$v.formData.name.$error"
                  focus
                  type="text"
                  name="name"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">{{ $t('validation.required') }} </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{ $tc('validation.name_min_length', $v.formData.name.$params.minLength.min, { count: $v.formData.name.$params.minLength.min }) }}
                  </span>
                </div>
              </div> 
              
                            
              <div class="form-group">
                <base-button
                  :loading="isLoading"
                  :disabled="isLoading"
                  icon="save"
                  color="theme"
                  type="submit"
                  class="collapse-button"
                >
                  {{ isEdit ? $t('ItemCat.update_item') : $t('ItemCat.save_item') }}
                </base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'
const { required, minLength, numeric, minValue, maxLength } = require('vuelidate/lib/validators')

export default {
  mixins: {
    validationMixin
  },
  data () {
    return {
      isLoading: false,
      title: 'Add Item',     
      formData: {
        name: '',        
      },
      
    }
  },
  computed: {
    
    
    
   
    isEdit () {   
      if (this.$route.name === 'itemscategory.edit') {
        return true
      }
      return false
    },
    isTaxPerItem () {
      return this.taxPerItem === 'YES' ? 1 : 0
    },
    getTaxTypes () {
      return this.taxTypes.map(tax => {
        return {...tax, tax_type_id: tax.id, tax_name: tax.name + ' (' + tax.percent + '%)'}
      })
    }
  },
  created () {
      
    if (this.isEdit) {   
      this.loadEditData()
    }
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3)
      },
      

    }
  },
  methods: {
    ...mapActions('itemCategory', [
      'addItem',
      'fetchItem',
      'updateItem'
    ]),
    ...mapActions('modal', [
      'openModal'
    ]),
    
    async loadEditData () {

      let response = await this.fetchItem(this.$route.params.id)

      this.formData = {...response.data.itemCat}
      

      
      
    },
    async submitItem () { 
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return false
      }

      
      let response
      if (this.isEdit) {
        this.isLoading = true
        response = await this.updateItem(this.formData)
      } else {
        let data = {
          ...this.formData        
        }       

        response = await this.addItem(data)

      }

      if (response.data) { 
        //console.log($route+"dfvbg")
        this.isLoading = false
        window.toastr['success'](this.$tc('ItemCat.updated_message'))
        this.$router.push('/admin/itemscategory')
        return true
      }
      window.toastr['error'](response.data.error)
    },
    async addItemUnit () {
      this.openModal({
        'title': this.$t('settings.customization.items.add_item_unit'),
        'componentName': 'ItemUnit'
      })
    }
  }
}
</script>
