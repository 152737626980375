var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "items main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "d-flex flex-row" }, [
          _c("div", [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$tc("ItemCat.title")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("ItemCat.title")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "router-link",
              {
                staticClass: "col-xs-2",
                attrs: { slot: "item-title", to: "itemscategory/create" },
                slot: "item-title"
              },
              [
                _c(
                  "base-button",
                  { attrs: { color: "theme", icon: "plus", size: "large" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tc("ItemCat.add_item_category")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            data: _vm.fetchData,
            "show-filter": false,
            "table-class": "table"
          }
        },
        [
          _c("table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "no-click"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectField,
                              expression: "selectField"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: { id: row.id, type: "checkbox" },
                          domProps: {
                            value: row.id,
                            checked: Array.isArray(_vm.selectField)
                              ? _vm._i(_vm.selectField, row.id) > -1
                              : _vm.selectField
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.selectField,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = row.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectField = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectField = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectField = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label", {
                          staticClass: "custom-control-label",
                          attrs: { for: row.id }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$tc("ItemCat.name"), show: "name" }
          }),
          _vm._v("\n      //"),
          _vm._v(
            '\n        //:label="$tc(\'ItemCat.added_on\')"\n        //sort-as="created_at"\n        //show="formattedCreatedAt"\n      ///>\n      '
          ),
          _c("table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$tc("ItemCat.action")) + " ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-dropdown",
                      [
                        _c(
                          "a",
                          {
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator"
                          },
                          [_c("dot-icon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  to: {
                                    path: "itemscategory/" + row.id + "/edit"
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "pencil-alt"] }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n              "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function($event) {
                                  return _vm.removeItems(row.id)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "dropdown-item-icon",
                                attrs: { icon: ["fas", "trash"] }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n              "
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }