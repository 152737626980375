var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "base-select multiselect",
      class: {
        "multiselect--active": _vm.isOpen,
        "multiselect--disabled": _vm.disabled,
        "multiselect--above": _vm.isAbove
      },
      attrs: {
        tabindex: _vm.searchable ? -1 : _vm.tabindex,
        "aria-owns": "listbox-" + _vm.id,
        role: "combobox"
      },
      on: {
        focus: function($event) {
          return _vm.activate()
        },
        blur: function($event) {
          _vm.searchable ? false : _vm.deactivate()
        },
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            if ($event.target !== $event.currentTarget) {
              return null
            }
            $event.preventDefault()
            return _vm.pointerForward()
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            if ($event.target !== $event.currentTarget) {
              return null
            }
            $event.preventDefault()
            return _vm.pointerBackward()
          }
        ],
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          ) {
            return null
          }
          $event.stopPropagation()
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.addPointerElement($event)
        },
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.deactivate()
        }
      }
    },
    [
      _vm._t(
        "caret",
        [
          _c("div", {
            staticClass: "multiselect__select",
            on: {
              mousedown: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.toggle()
              }
            }
          })
        ],
        { toggle: _vm.toggle }
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "tags",
          staticClass: "multiselect__tags",
          class: { "in-valid": _vm.invalid }
        },
        [
          _vm._t(
            "selection",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visibleValues.length > 0,
                      expression: "visibleValues.length > 0"
                    }
                  ],
                  staticClass: "multiselect__tags-wrap"
                },
                [
                  _vm._l(_vm.visibleValues, function(option, index) {
                    return [
                      _vm._t(
                        "tag",
                        [
                          _c(
                            "span",
                            { key: index, staticClass: "multiselect__tag" },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.getOptionLabel(option)
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "multiselect__tag-icon",
                                attrs: { tabindex: "1" },
                                on: {
                                  keypress: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return _vm.removeElement(option)
                                  },
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                    return _vm.removeElement(option)
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        {
                          option: option,
                          search: _vm.search,
                          remove: _vm.removeElement
                        }
                      )
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.internalValue && _vm.internalValue.length > _vm.limit
                ? [
                    _vm._t("limit", [
                      _c("strong", {
                        staticClass: "multiselect__strong",
                        domProps: {
                          textContent: _vm._s(
                            _vm.limitText(_vm.internalValue.length - _vm.limit)
                          )
                        }
                      })
                    ])
                  ]
                : _vm._e()
            ],
            {
              search: _vm.search,
              remove: _vm.removeElement,
              values: _vm.visibleValues,
              isOpen: _vm.isOpen
            }
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "multiselect__loading" } },
            [
              _vm._t("loading", [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "multiselect__spinner"
                })
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("input", {
            ref: "search",
            class: ["multiselect__input"],
            style: _vm.inputStyle,
            attrs: {
              name: _vm.name,
              id: _vm.id,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              tabindex: _vm.tabindex,
              "aria-controls": "listbox-" + _vm.id,
              type: "text",
              autocomplete: "off",
              spellcheck: "false"
            },
            domProps: { value: _vm.search },
            on: {
              input: function($event) {
                return _vm.updateSearch($event.target.value)
              },
              focus: function($event) {
                $event.preventDefault()
                return _vm.activate()
              },
              blur: function($event) {
                $event.preventDefault()
                return _vm.deactivate()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.deactivate()
              },
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.pointerForward()
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.pointerBackward()
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del"
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.removeLastElement()
                }
              ],
              keypress: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                $event.stopPropagation()
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.addPointerElement($event)
              }
            }
          }),
          _vm._v(" "),
          _vm.isSingleLabelVisible
            ? _c(
                "span",
                {
                  staticClass: "multiselect__single",
                  on: {
                    mousedown: function($event) {
                      $event.preventDefault()
                      return _vm.toggle($event)
                    }
                  }
                },
                [
                  _vm._t(
                    "singleLabel",
                    [[_vm._v(_vm._s(_vm.currentOptionLabel))]],
                    { option: _vm.singleValue }
                  )
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "multiselect" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ],
            ref: "list",
            staticClass: "multiselect__content-wrapper",
            style: { maxHeight: _vm.optimizedHeight + "px" },
            attrs: { tabindex: "-1" },
            on: {
              focus: _vm.activate,
              mousedown: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "ul",
              {
                staticClass: "multiselect__content",
                style: _vm.contentStyle,
                attrs: { id: "listbox-" + _vm.id, role: "listbox" }
              },
              [
                _vm._t("beforeList"),
                _vm._v(" "),
                _vm.multiple && _vm.max === _vm.internalValue.length
                  ? _c("li", [
                      _c(
                        "span",
                        { staticClass: "multiselect__option" },
                        [
                          _vm._t("maxElements", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("validation.maximum_options_error", {
                                    max: _vm.max
                                  })
                                ) +
                                " "
                            )
                          ])
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.max || _vm.internalValue.length < _vm.max
                  ? _vm._l(_vm.filteredOptions, function(option, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "multiselect__element",
                          attrs: {
                            id: _vm.id + "-" + index,
                            role: !(
                              option &&
                              (option.$isLabel || option.$isDisabled)
                            )
                              ? "option"
                              : null
                          }
                        },
                        [
                          !(option && (option.$isLabel || option.$isDisabled))
                            ? _c(
                                "span",
                                {
                                  staticClass: "multiselect__option",
                                  class: _vm.optionHighlight(index, option),
                                  attrs: {
                                    "data-select":
                                      option && option.isTag
                                        ? _vm.tagPlaceholder
                                        : _vm.selectLabelText,
                                    "data-selected": _vm.selectedLabelText,
                                    "data-deselect": _vm.deselectLabelText
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.select(option)
                                    },
                                    mouseenter: function($event) {
                                      if (
                                        $event.target !== $event.currentTarget
                                      ) {
                                        return null
                                      }
                                      return _vm.pointerSet(index)
                                    }
                                  }
                                },
                                [
                                  _vm._t(
                                    "option",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.getOptionLabel(option))
                                        )
                                      ])
                                    ],
                                    { option: option, search: _vm.search }
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          option && (option.$isLabel || option.$isDisabled)
                            ? _c(
                                "span",
                                {
                                  staticClass: "multiselect__option",
                                  class: _vm.groupHighlight(index, option),
                                  attrs: {
                                    "data-select":
                                      _vm.groupSelect &&
                                      _vm.selectGroupLabelText,
                                    "data-deselect":
                                      _vm.groupSelect &&
                                      _vm.deselectGroupLabelText
                                  },
                                  on: {
                                    mouseenter: function($event) {
                                      if (
                                        $event.target !== $event.currentTarget
                                      ) {
                                        return null
                                      }
                                      _vm.groupSelect && _vm.pointerSet(index)
                                    },
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      return _vm.selectGroup(option)
                                    }
                                  }
                                },
                                [
                                  _vm._t(
                                    "option",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.getOptionLabel(option))
                                        )
                                      ])
                                    ],
                                    { option: option, search: _vm.search }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showNoOptions &&
                _vm.options.length === 0 && !_vm.search && !_vm.loading
                  ? _c("li", [
                      _c(
                        "span",
                        { staticClass: "multiselect__option" },
                        [
                          _vm._t("noOptions", [
                            _vm._v(_vm._s(_vm.$t("general.list_is_empty")))
                          ])
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm._t("afterList")
          ],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }